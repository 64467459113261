import React, { useEffect, useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  ListItemText,
  DialogContent,
  InputAdornment,
  Modal,
  Chip
} from "@mui/material";
import { EditOutlined as EditIcon, Add as AddIcon } from "@mui/icons-material";
import useStyles from "./style"; 
import frontendConfig from "@/config/frontend";
import {
    DeleteOutline as DeleteIcon,
    LaunchOutlined as AccessUserAccountIcon,
    VpnKeyOutlined as VpnKeyIcon,
    CheckCircleOutline as CheckCircleIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Check as CheckIcon,
    FileCopy as FileCopyIcon,
} from "@mui/icons-material";
import { SelectChangeEvent } from "@mui/material/Select";
import ApiService from "@/services/Api";
import { useSnackbar } from 'notistack';
import {
    CodecRoleType,
    CompanyType,
    ProductType,
    UserType,
    IntranetRoleType
} from "@/pages/Intranet/Companies";
import CloseIcon from "@mui/icons-material/Close";

interface ValidationErrors {
    name?: string;
    email?: string;
    product?: string;
    companyUuids?: string;
}

interface IUser {
  uuid?: string;
  companyUuid: string;
  name: string;
  email: string;
  product: ProductType
  role: CodecRoleType | IntranetRoleType
  pictureKey: string | null;
  preferences: any; 
  isBetaTester: boolean;
  createdAt: string;
  lastHeardAt: string | null;
  updatedAt: string;
  companyUuids: string[]; 
}

interface IAddress {
    uuid: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string | null;
    street: string | null;
    number: string | null;
    complement: string | null;
    latitude: number | null;
    longitude: number | null;
}

interface IRadio {
    uuid: string;
    name: string;
    address: IAddress;
}




const Users: React.FC = () => {
    
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [radios, setRadios] = useState<IRadio[]>([]);
  const [openedUserDialog, setOpenedUserDialog] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [betaTester, setBetaTester] = useState<boolean | undefined>(false);
  const [hidden, setHidden] = useState(true);
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openedPasswordDialog, setOpenedPasswordDialog] = useState(false);
  const [companies, setCompanies] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [openedCompaniesDialog, setOpenedCompaniesDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [
      openedRequestPasswordRecoveryDialog,
      setOpenedRequestPasswordRecoveryDialog,
  ] = useState<boolean>(false);
  const openCompaniesDialog = (user: IUser) => {
      setSelectedUser(user);
      setOpenedCompaniesDialog(true);
  };

  const handleCloseCompaniesDialog = () => {
      setOpenedCompaniesDialog(false);
      setSelectedUser(null);
  };

  const [loadingRequestPasswordRecovery, setLoadingRequestPasswordRecovery] =
      useState<boolean>(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
      PaperProps: {
          style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
          },
      },
  };

  
  const fetchRadios = async () => {
      try {
          const response = await ApiService.get("/company/list");
          return response.data;
      } catch (error) {
          console.error("Erro ao buscar rádios: ", error);
          return [];
      }
  };

  useEffect(() => {
      const loadRadios = async () => {
          const fetchedRadios = await fetchRadios();
          setRadios(fetchedRadios);
      };
      loadRadios();
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof companies>) => {
      const {
          target: { value },
      } = event;

      const newValue = Array.isArray(value) ? value : value.split(",");
      setCompanies(newValue);
  };

  const fetchUsers = async () => {
      try {
          const response = await ApiService.get("/user/list");
          return response.data;
      } catch (error) {
          console.error("Erro ao buscar usuários: ", error);
          return [];
      }
  };

  useEffect(() => {
      const loadUsers = async () => {
          const fetchedUsers = await fetchUsers();
          setUsers(fetchedUsers);
      };
      loadUsers();
  }, []);

  const filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reseta após 2 segundos
      });
  };

  const handleOpenUserDialog = (userToEdit?: IUser) => {
      setNewPassword(null);
      setBetaTester(user?.isBetaTester);
      setUser((prevUser) => {
          if (!prevUser) {
              return null;
          }

          return {
              ...prevUser,
              product: "codec",
              role: "communicator",
              isBetaTester: false,
          };
      });

      const initialUser = userToEdit || {
          uuid: "",
          companyUuid: "",
          name: "",
          email: "",
          product: "metrics",
          role: "admin",
          pictureKey: null,
          preferences: null,
          isBetaTester: false,
          createdAt: "",
          lastHeardAt: null,
          updatedAt: "",
          companyUuids: [],
      };

      setUser(initialUser);
    if (initialUser.companyUuids.length === 0 && initialUser.companyUuid) {
        setCompanies([initialUser.companyUuid]); // Define como um array com o único ID
    } else {
        setCompanies(initialUser.companyUuids); // Define os IDs existentes
    }
      setOpenedUserDialog(true);
  };



const handleAccessUserAccount = async (user: IUser) => {
    try {
        const { data: token } = await ApiService.post(
            `/company/${user.companyUuid}/user/${user.uuid}/token`
        );

        const url = `http://${user.product}.${frontendConfig.frontendBaseUrl}/?token=${token}`;

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.open(url, "_blank")?.focus();
    } catch (error: any) {
        enqueueSnackbar(
            error?.data ||
                "Ocorreu um erro ao tentar acessar a conta do usuario.",
            {
                variant: "error",
            }
        );
    }
};

const handleAccessUserAccount_V2 = async (user: IUser) => {
    try {
        const { data: token } = await ApiService.post(
            `/company/${user.companyUuid}/user/${user.uuid}/token`
        );

        const url = `http://${user.product}-v2.${frontendConfig.frontendBaseUrl}/login?token=${token}`;

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.open(url, "_blank")?.focus();
    } catch (error: any) {
        enqueueSnackbar(
            error?.data ||
                "Ocorreu um erro ao tentar acessar a conta do usuario.",
            {
                variant: "error",
            }
        );
    }
};


  const handleCloseUserDialog = () => {
    setOpenedUserDialog(false);
    setUser(null);
  };

  interface ValidationErrors {
    name?: string;
    email?: string;
    product?: string;
    companyUuids?: string;
}

const validateUser = (user: IUser): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (!user?.name) {
        errors.name = "O campo nome é obrigatório.";
    }
    if (!user?.email) {
        errors.email = "O campo email é obrigatório.";
    }
    if (!user?.product) {
        errors.product = "O campo produto é obrigatório.";
    }
    if (!user?.companyUuids || user?.companyUuids.length === 0) {
        errors.companyUuids = "Pelo menos uma companhia deve ser selecionada.";
    }

    return errors;
};

const saveUser = async () => {
    const newUser: IUser = {
        uuid: user?.uuid || undefined,
        companyUuid:companies[0],
        companyUuids: companies,
        name: user?.name || "",
        email: user?.email || "",
        product: user?.product || "metrics",
        role: user?.role || "admin",
        isBetaTester: user?.isBetaTester || false,
        pictureKey: user?.pictureKey || null,
        preferences: user?.preferences || null,
        createdAt: user?.createdAt || "",
        lastHeardAt: user?.lastHeardAt || null,
        updatedAt: user?.updatedAt || "",
    };

    // Chamar a função de validação
    const errors = validateUser(newUser);

    if (Object.keys(errors).length > 0) {
        Object.values(errors).forEach(error => {
            enqueueSnackbar(error, { variant: "error" });
        });
        return; 
    }

    // Se o usuário não possui um UUID, é um novo usuário
    const isNewUser = !user?.uuid;

    try {
        const response = await ApiService.post("/user", newUser);
        if (response.data) {
            const fetchedUsers = await fetchUsers();
            setUsers(fetchedUsers);
            enqueueSnackbar("Usuário salvo com sucesso.", { variant: "success" });
            handleCloseUserDialog();

            // Apenas setar a nova senha se for um novo usuário
            if (isNewUser) {
                setNewPassword(response.data.password);
                setOpenedPasswordDialog(true);
            }
        }
    } catch (error: any) {
        console.error("Erro ao salvar usuário:", error);
         enqueueSnackbar(`${error.data}`, { variant: "error" });
    }
};




const [isModalOpen, setIsModalOpen] = useState(false);
const handleOpenModal = () => setIsModalOpen(true);
const handleCloseModal = () => setIsModalOpen(false);
const [recoveryPasswordDisabledEmails] = useState<Array<string>>([]);

const handleRequestPasswordRecovery = async () => {
    setLoadingRequestPasswordRecovery(true);

    try {
        if (recoveryPasswordDisabledEmails.includes(user?.email || "")) {
            throw new Error(
                "Aguarde 30 segundos para solicitar a recuperação de senha novamente."
            );
        }

        await ApiService.get(
            `metrics|recovery-password/email/${user?.email}`
        );

        recoveryPasswordDisabledEmails.push(user?.email || "");

        enqueueSnackbar(
            "O link para recuperação da senha foi enviado com sucesso para o email do usuário.",
            { variant: "success" }
        );

        await setTimeout(
            () =>
                recoveryPasswordDisabledEmails.splice(
                    recoveryPasswordDisabledEmails.indexOf(
                        user?.email || ""
                    ),
                    1
                ),
            30000
        );
    } catch (error: any) {
        enqueueSnackbar(
            error?.message ||
                error?.data ||
                "Ocorreu um erro ao requisitar a troca de senha.",
            { variant: "error" }
        );
    }

    setLoadingRequestPasswordRecovery(false);

    setOpenedRequestPasswordRecoveryDialog(false);
};

const handleDeleteUser = async () => {
    setLoadingDeleteUser(true);
    try {

        await ApiService.delete(
            `/company/${user?.companyUuid}/user/${user?.uuid}`
        );

        setOpenedDeleteUserDialog(false);
        setUser(prevUser => {
            if (!prevUser) return null; 
        
            return {
                product: "codec", 
                role: "communicator", 
                isBetaTester: false, 
                companyUuid: prevUser.companyUuid, 
                name: prevUser.name, 
                email: prevUser.email, 
                pictureKey: prevUser.pictureKey, 
                preferences: prevUser.preferences, 
                createdAt: prevUser.createdAt, 
                lastHeardAt: prevUser.lastHeardAt, 
                updatedAt: new Date().toISOString(), 
                companyUuids: prevUser.companyUuids, 
            };
        });
        

        enqueueSnackbar("Usuário removido com sucesso.", {
            variant: "success",
        });
    } catch (error: any) {
        enqueueSnackbar(
            error?.data || "Ocorreu um erro ao remover o usuário.",
            {
                variant: "error",
            }
        );
    }

    setLoadingDeleteUser(false);
    const fetchedUsers = await fetchUsers();
    setUsers(fetchedUsers);

};

  const [openedDeleteUserDialog, setOpenedDeleteUserDialog] =
  useState<boolean>(false);
  const [loadingDeleteUser, setLoadingDeleteUser] = useState<boolean>(false);

  const openDeleteUserDialog = async (user: IUser) => {
    setUser(user);
    setOpenedDeleteUserDialog(true);
};

const selectProduct = ({
    target,
}: {
    target: {
        name?: string | undefined;
        value: unknown;
    };
}) => {
    setUser(prevUser => {
        if (!prevUser) return null; // Retorna null se prevUser for null

        const isBetaTesterValue = target.value === "metrics" ? (betaTester ?? false) : false; // Garante que é um booleano

        return {
            ...prevUser, // Mantém as propriedades existentes
            product: target.value as ProductType, // Atualiza product
            isBetaTester: isBetaTesterValue, // Garante que é sempre um booleano
        };
    });
};







  return (
      <>
          <Grid container spacing={3}>
              {/* Campo de busca e botão de criação */}
              <Grid item xs={6}>
                  <TextField
                      fullWidth
                      label="Buscar usuário"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      size="small"
                  />
              </Grid>
              <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
              >
                  <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => handleOpenUserDialog()}
                      size="small"
                  >
                      Criar Usuário
                  </Button>
              </Grid>

              {/* Tabela de usuários */}
              <Grid item xs={12}>
                  <TableContainer component={Paper} className={classes.table}>
                      <Table aria-label="users table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>Nome</TableCell>
                                  <TableCell align="right">E-mail</TableCell>
                                  <TableCell align="right">Companhia</TableCell>
                                  <TableCell align="right">Produto</TableCell>
                                  <TableCell align="right" />
                                  <TableCell align="right">Ações</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {filteredUsers.map((user) => (
                                  <TableRow key={user.uuid}>
                                      <TableCell component="th" scope="row">
                                          {user.name}
                                      </TableCell>
                                      <TableCell align="right">
                                          {user.email}
                                      </TableCell>
                                      <TableCell align="right">
                                          {user.companyUuids.length > 1 ? (
                                              <Button
                                                  onClick={() =>
                                                      openCompaniesDialog(user)
                                                  }
                                              >
                                                  Ver Companhias (
                                                  {user.companyUuids.length})
                                              </Button>
                                          ) : (
                                              radios.find(
                                                  (r) =>
                                                      r.uuid ===
                                                      user.companyUuid
                                              )?.name || "Nenhuma companhia"
                                          )}
                                      </TableCell>
                                      <TableCell align="right">
                                          {user.product}
                                      </TableCell>
                                      <TableCell align="right">
                                        {user.isBetaTester && (
                                            <Chip
                                                className={classes.betaChip}
                                                size="medium"
                                                label="BETA"
                                            />
                                        )}
                                    </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                            onClick={() => {
                                                setUser(user);
                                                setOpenedRequestPasswordRecoveryDialog(
                                                    true
                                                );
                                            }}
                                        >
                                            <VpnKeyIcon />
                                        </IconButton>
                                        {user?.product === "metrics" ? (
                                            <>
                                                <IconButton
                                                    onClick={handleOpenModal}
                                                    title="metrics options"
                                                >
                                                    <AccessUserAccountIcon />
                                                </IconButton>

                                                <Modal
                                                    open={isModalOpen}
                                                    onClose={handleCloseModal}
                                                >
                                                    <div
                                                        style={{
                                                            position:
                                                                "relative",
                                                            alignItems:
                                                                "center",
                                                            padding: "20px",
                                                            backgroundColor:
                                                                "white",
                                                            margin: "250px auto",
                                                            width: "300px",
                                                            textAlign: "center",
                                                            justifyContent:
                                                                "center",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={
                                                                handleCloseModal
                                                            }
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                top: "5px",
                                                                right: "5px",
                                                                color: "#707070",
                                                                marginBottom:
                                                                    "50px",
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>

                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                marginTop:
                                                                    "20px",
                                                                width: "200px",
                                                            }}
                                                            onClick={() => {
                                                                handleAccessUserAccount(
                                                                    user
                                                                );
                                                                handleCloseModal();
                                                            }}
                                                        >
                                                            Metrics-v1
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                                width: "200px",
                                                            }}
                                                            onClick={() => {
                                                                handleAccessUserAccount_V2(
                                                                    user
                                                                );
                                                                handleCloseModal();
                                                            }}
                                                        >
                                                            Metrics-v2
                                                        </Button>
                                                    </div>
                                                </Modal>
                                            </>
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    handleAccessUserAccount(
                                                        user
                                                    )
                                                }
                                                title={user?.product}
                                            >
                                                <AccessUserAccountIcon />
                                            </IconButton>
                                        )}

                                        <IconButton
                                            onClick={() => handleOpenUserDialog(user)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                openDeleteUserDialog(user)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </Grid>
          </Grid>
          <Dialog
              open={openedCompaniesDialog}
              onClose={handleCloseCompaniesDialog}
          >
              <DialogTitle>Companhias Associadas</DialogTitle>
              <DialogContent>
                  <Typography>
                      {selectedUser?.companyUuids
                          .map((uuid) => {
                              const company = radios.find(
                                  (r) => r.uuid === uuid
                              );
                              return company ? company.name : "";
                          })
                          .filter(Boolean) 
                          .join(", ")}
                  </Typography>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseCompaniesDialog} color="primary">
                      Fechar
                  </Button>
              </DialogActions>
          </Dialog>
          {/* Dialog para criar/editar usuários */}
          <Dialog open={openedUserDialog}>
              <DialogTitle>
                  {!newPassword
                      ? !user?.uuid
                          ? "Criando novo usuário"
                          : "Editando o usuário"
                      : "Sucesso"}
              </DialogTitle>

              {loadingUser && !user?.uuid && (
                  <Grid container justifyContent="center">
                      <CircularProgress size={80} color="primary" />
                  </Grid>
              )}

              {(!loadingUser || user?.uuid) && !newPassword && user && (
                  <>
                      <Grid container spacing={2} style={{ padding: "16px" }}>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                  fullWidth
                                  label="Nome"
                                  size="small"
                                  variant="outlined"
                                  value={user.name}
                                  onChange={(e) =>
                                      setUser({ ...user, name: e.target.value })
                                  }
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                  fullWidth
                                  label="Email"
                                  size="small"
                                  variant="outlined"
                                  value={user.email}
                                  onChange={(e) =>
                                      setUser({
                                          ...user,
                                          email: e.target.value,
                                      })
                                  }
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <FormControl
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                              >
                                  <InputLabel>Produto</InputLabel>
                                  <Select
                                            value={user.product}
                                            onChange={selectProduct}
                                            variant="outlined"
                                            color="primary"
                                            label="Produto"
                                            className={classes.select}
                                        >
                                            <MenuItem value="metrics">
                                                Metrics
                                            </MenuItem>
                                            <MenuItem value="club">
                                                Clube de vantagens
                                            </MenuItem>
                                        </Select>
                              </FormControl>
                          </Grid>
                          <Grid
                              item
                              xs={12}
                              sm={6}
                              className={classes.dialogItemInput}
                          >
                              <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  sx={{ width: 250 }}
                              >
                                  <InputLabel style={{marginTop:-5}} id="demo-multiple-checkbox-label">Rádio</InputLabel>
                                  <Select
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={companies}
                                      variant="outlined"
                                      onChange={handleChange}
                                      label="Rádio"
                                      renderValue={(selected) =>
                                          selected
                                              .map((uuid) => {
                                                  const radio = radios.find(
                                                      (r) => r.uuid === uuid
                                                  );
                                                  return radio
                                                      ? `${radio.name} - ${radio.address.city}`
                                                      : "";
                                              })
                                              .join(", ")
                                      }
                                      MenuProps={MenuProps}
                                      className={classes.select}
                                  >
                                      {radios.map((radio) => (
                                          <MenuItem
                                              key={radio.uuid}
                                              value={radio.uuid}
                                          >
                                              <Checkbox
                                                  checked={
                                                      companies.indexOf(
                                                          radio.uuid
                                                      ) > -1
                                                  }
                                              />
                                              <ListItemText
                                                  primary={`${radio.name} - ${radio.address.city}`}
                                              />
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          checked={user.isBetaTester}
                                          onChange={(e) =>
                                              setUser({
                                                  ...user,
                                                  isBetaTester:
                                                      e.target.checked,
                                              })
                                          }
                                      />
                                  }
                                  label="Acesso a recursos em beta"
                              />
                          </Grid>
                      </Grid>

                      <Divider />

                      <DialogActions>
                          <Button
                              onClick={handleCloseUserDialog}
                              color="primary"
                          >
                              Cancelar
                          </Button>
                          <Button
                              onClick={saveUser}
                              color="primary"
                              variant="contained"
                          >
                              {user.uuid ? "Salvar" : "Criar"}
                          </Button>
                      </DialogActions>
                  </>
              )}
          </Dialog>
          {openedPasswordDialog && (
              <Dialog
                  open={openedPasswordDialog}
                  onClose={() => setOpenedPasswordDialog(false)}
              >
                  <DialogTitle>Senha Gerada</DialogTitle>
                  <DialogContent>
                      <Grid
                          container
                          className="dialogGridContent"
                          alignContent="center"
                          justifyContent="center"
                      >
                          <Typography>
                              Atenção! Você está visualizando uma senha gerada
                              aleatoriamente que não poderá ser exibida
                              novamente. Para alterar a senha, é preciso
                              solicitar a redefinição.
                          </Typography>

                          <Grid item xs={12} style={{ marginTop: 16 }}>
                              <FormControl variant="outlined" fullWidth>
                                  <TextField
                                      variant="outlined"
                                      label="Senha"
                                      type={hidden ? "password" : "text"}
                                      size="small"
                                      disabled
                                      value={newPassword}
                                      InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <IconButton
                                                      aria-label="Copia senha para área de trabalho"
                                                      onClick={() => {
                                                          if (newPassword) {
                                                              handleCopy(
                                                                  newPassword
                                                              ); // Chama handleCopy somente se newPassword não for null
                                                          }
                                                      }}
                                                      edge="end"
                                                  >
                                                      {copied ? (
                                                          <CheckIcon />
                                                      ) : (
                                                          <FileCopyIcon />
                                                      )}
                                                  </IconButton>

                                                  <IconButton
                                                      aria-label="Torna senha visível"
                                                      onClick={() =>
                                                          setHidden(!hidden)
                                                      }
                                                      edge="end"
                                                  >
                                                      {hidden ? (
                                                          <VisibilityOffIcon />
                                                      ) : (
                                                          <VisibilityIcon />
                                                      )}
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }}
                                  />
                              </FormControl>
                          </Grid>

                          <Divider style={{ margin: "16px 10px" }} />

                          <Button
                              style={{ marginTop: 20 }}
                              color="primary"
                              variant="contained"
                              onClick={() => setOpenedPasswordDialog(false)}
                              autoFocus
                          >
                              Fechar
                          </Button>
                      </Grid>
                  </DialogContent>
              </Dialog>
          )}
                    <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteUserDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover o usuário {user?.name}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteUserDialog(false)}
                        className={classes.deleteUserDialogCancelButton}
                        disabled={loadingDeleteUser}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteUser}
                        color="primary"
                        autoFocus
                        disabled={loadingDeleteUser}
                        endIcon={
                            loadingDeleteUser && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedRequestPasswordRecoveryDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja solicitar a recuperação de senha para
                    o usuário {user?.name}?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() =>
                            setOpenedRequestPasswordRecoveryDialog(false)
                        }
                        className={
                            classes.RequestPasswordRecoveryDialogCancelButton
                        }
                        disabled={loadingRequestPasswordRecovery}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleRequestPasswordRecovery}
                        color="primary"
                        autoFocus
                        disabled={loadingRequestPasswordRecovery}
                        endIcon={
                            loadingRequestPasswordRecovery && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Solicitar
                    </Button>
                </DialogActions>
            </Dialog>
      </>
  );
};

export default Users;
