import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    campaignDialogContainer: {
        // padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    dialogDateItemInput: {
        padding: theme.spacing(1),
        height: '56px !important'
    },
    dialogSelectItemInput: {
        padding: theme.spacing(1),
        height: '72px !important'
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%',
        maxWidth: '100%'
    },
    select: {
        // height: theme.spacing()
    },
    fromInput: {
        // width: '100%',
        height: '40px !important',
        width: '284px !important'
        // paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1)
    },
    descriptionColumn: {
        maxWidth: '250px'
    }
}))

export default useStyles
