import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    companyDialogContainer: {
        // padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    filterEnabledCheckbox: {
        marginLeft: theme.spacing(3)
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        width: '100%'
    },
    selectInput: {
        marginTop: '-7px'
    },
    select: {
        height: theme.spacing(5)
    },
    rangeInputLabel: {
        color: theme.palette.secondary.light
    }
}))

export default useStyles
